import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import ReactImageFallback from 'react-image-fallback';
import { Redux } from 'src/redux/redux.interface';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { useCustomRouter } from '@hooks/useCustomRouter';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { useGTM } from '@hooks/useGTM';
import { useJourneyType } from '@hooks/useJourneyType';
import { usePrices } from '@hooks/usePriceCalculator';
import { useTranslations } from '@hooks/useTranslations';
import { OfferListItemProps } from './';
import { useExteriorPreviewImage } from '@utils/Image.utils';
import { getOfferCarTitle } from './utils/getOfferCartitle';
import { useSummaryDataPromise } from './hooks/useSummaryDataPromise';
import Button from '../Button/index';
import { Col } from '../Grid/index';
import PriceBox from '../PriceBox/Configurable/index';
import SOLPriceBox from '../PriceBox/SOL/index';
import { useGetModelKeywords, useGetOfferKeywords, useGetPaymentKeywords } from './utils/getOfferKeywords';
import { buildParameterizedPathname, extractParamsFromPathname } from '@utils/url.utils';
import routes from '../../constants/routes';
import { CarJourneyType } from '../../services';
import queryString from 'query-string';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { useDispatch, useSelector } from 'react-redux';
import FilterDuck from '../../redux/filters/filter.duck';
import { ICalculateSummaryRequestData } from '../../services/calculateSummary/calculateSummary.types';
import styled from 'styled-components';
import { rem } from 'polished';
import Link from '../CustomNextLink';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { findDefaultConfiguration } from '@utils/Offers.utils';
import { PaymentJourneyTypes } from '../../partExchange/interfaces/Default';
import { isBrandAC, isBrandOV, isMarketGB } from '../../constants/main';
import NeedHandSection from './NeedHandSection';
import { HandledComponentError } from '@components/ErrorHandling';
import MarketingSticker from '../MarketingSticker';
import { EContextPage, EType, findPromoByPageAndType } from '@utils/Promo.utils';
import { SortType, StockType } from '../../services/stock/models/stockItems.service.model';
import { UNAVAILABLE_CAR_IMAGE } from '../../constants/images';
import { getFinanceDetailText, getFinanceDetailView, getMonthlyPriceObjByType } from '@utils/Price.utils';
import { StockFormatedPriceType } from '../../services/stock/models/stockItem.price.model';
import { IOffer, IPromo } from '../../interfaces/IOfferItem';
import { GtmData } from '../../types/gtm';
import { addSearchParams } from 'src/services/stock/utils';
import { useRouter } from 'next/router';
import DynamicExteriorImagePreview from '@components/DynamicExteriorImagePreview';
import DemoVehicleLabel from '@components/DemoVehicleLabel';
import { STOCK_TYPE } from '../../services/filters/filters';

const StyledCol = styled(Col)`
    margin-bottom: ${rem(15)};
`;

const NEED_HAND_ELEMENT_ID = 'needHandSection';
const OFFER_ELEMENT_CLASS = 'offer-item';

export const OfferListItemTemplate: FC<OfferListItemProps> = memo(
    ({ className, offer, disabled, isNeedHandItem = false, onImageLoad, index }) => {
        const dispatch = useDispatch();
        const router = useRouter();
        const { cash, finance, catalog } = usePrices(offer, true, null, true);
        const {
            cash: { totalPrice: defaultTotalPrice },
            finance: { totalPrice: defaultMonthlyPrice },
        } = usePrices(offer, true, null, false);
        const amountRounding = getMonthlyPriceObjByType(offer.prices, StockFormatedPriceType.EMPLOYEE)?.amountRounding;
        const financeDetailView = getFinanceDetailView(offer.prices, StockFormatedPriceType.EMPLOYEE);
        const financeDetailText = getFinanceDetailText(offer.prices, StockFormatedPriceType.EMPLOYEE, null, null, null);

        const shouldIncludeBodyStyleDescriptionForStock = useFeatureSwitchEnabled(
            FEATURES_LIST.FEATURE_SWITCH_STOCK_SHOW_GR_BODY_STYLE_IN_CAR_TITLE
        );

        const shouldIncludeBodyStyleDescriptionForMTO = offer?.extraFields?.displayGrBodyStyleLabel;

        const { t } = useTranslations();
        const { isStockJourney } = useCarStockJourneyCheck();

        const query = router.query;
        const { paymentJourneyType } = useJourneyType();

        const { totalPrice: baseTotalPrice, totalBasicPriceWithoutVat } = cash;
        const {
            totalPrice: baseMonthlyPrice,
            financeProperties: { deposit, duration, annualMileage, apr, hasMonthlyPrices },
        } = finance;

        const { totalPrice: catalogPrice } = catalog;

        const depositPrice = deposit;

        const geoLocation = useSelector((state: Redux) => FilterDuck.getGeoLocation(state));
        const distanceRadiusValue = useSelector((state: Redux) => FilterDuck.getDistanceRadius(state));
        const geoLocationName = useSelector((state: Redux) => FilterDuck.getGeoLocationName(state));
        const areGeoLocationParamsDefined =
            geoLocation && typeof distanceRadiusValue === 'number' && Boolean(geoLocationName);

        const { pushProductClick, pushToDataLayer } = useGTM();
        const isBenefitsEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_BENEFITS_MODE_ENABLED);

        const defaultConfiguration = findDefaultConfiguration(offer.offers, paymentJourneyType as PaymentJourneyTypes);
        const allowDefaultConfiguration = isBenefitsEnabled && defaultConfiguration;
        const imageSource = allowDefaultConfiguration ? defaultConfiguration : offer;
        const image = useExteriorPreviewImage(imageSource.images ?? offer.images, 'list');
        let carConfigId: string;
        if (isStockJourney) {
            carConfigId = offer.externalId;
        } else {
            carConfigId = offer._id;
        }

        const params: ICalculateSummaryRequestData = isStockJourney
            ? { carConfigId, carConfiguration: offer }
            : { carConfigId };

        const { processCalculateSummaryDataPromise } = useSummaryDataPromise(params);

        const offerUrl = useMemo(() => {
            const { carJourney } = extractParamsFromPathname(router.asPath);
            const pathname = buildParameterizedPathname(routes.SELECTOR, carJourney as CarJourneyType, {
                nameplateBodyStyleSlug: offer.nameplateBodyStyleSlug,
                ...(isStockJourney && { modelId: offer.model.id }),
                ...(isStockJourney && { bodyStyleId: offer.bodyStyle.id }),
            });
            const search = queryString.stringify(query);
            let searchString = search ? `?${search}` : '';

            if (isStockJourney && !searchString.includes(`sort=${SortType.PRICE}`)) {
                searchString = addSearchParams(searchString, 'sortPrice');
            }

            if (isStockJourney && areGeoLocationParamsDefined && !searchString.includes(`sort=${SortType.PROXIMITY}`)) {
                searchString = addSearchParams(searchString, 'sortProximity');
            }

            if (isStockJourney && areGeoLocationParamsDefined && !searchString.includes(`idsitegeo`)) {
                searchString = addSearchParams(searchString, 'idsitegeo', query);
            }

            return `${pathname}${searchString}`;
        }, [query, router]);

        // redirect to offer logic
        const handleShowOfferClick = useCallback(
            (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLHeadingElement, MouseEvent>) => {
                event.preventDefault();
                dispatch(UIDuck.setScrollPosition((event.target as HTMLElement).id + '_' + offer.index));
                pushProductClick('home', offer);
                pushToDataLayer({
                    eventCategory: 'SolRetail::VehicleList',
                    eventAction: 'Select::VehicleModel',
                    eventLabel: offer.bodyStyle.title,
                } as GtmData);

                router.push(offerUrl);
            },
            [dispatch, offerUrl, offer]
        );

        const isSOLEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED);
        const isPromoEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_PROMO_ENABLED);
        const isMarketingStickerEnabled =
            isPromoEnabled &&
            process.browser &&
            findPromoByPageAndType(offer.offers as (IOffer & { type: EType })[], EContextPage.home, isStockJourney);
        // hack #1 - React does not append class on some of the elements because of some virtual DOM diffing issue
        const containerRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (
                !isNeedHandItem &&
                containerRef.current &&
                Boolean(isMarketingStickerEnabled) &&
                !containerRef.current.className.includes('with-sticker')
            ) {
                containerRef.current.className += ' with-sticker';
            }
        }, [containerRef.current]);

        // hack #3 - React does not remove class on some of the elements because of some virtual DOM diffing issue
        useEffect(() => {
            if (
                containerRef.current &&
                Boolean((isPromoEnabled && offer.hasPromoCode) || Boolean(isMarketingStickerEnabled))
            ) {
                containerRef.current.className = `${className} ${!isNeedHandItem ? 'with-sticker' : ''} ${
                    isNeedHandItem ? 'isNeedHandItem' : ''
                }`;
            } else {
                containerRef.current.className = `${className} ${isNeedHandItem ? 'isNeedHandItem' : ''}`;
            }
        }, [(isPromoEnabled && offer.hasPromoCode) || isMarketingStickerEnabled]);

        useEffect(() => {
            if (offer) {
                const needAHandElement = document.getElementById(NEED_HAND_ELEMENT_ID);
                const offerElement = document.getElementsByClassName(OFFER_ELEMENT_CLASS)?.[0];

                if (needAHandElement) needAHandElement.style.height = `${(offerElement as HTMLElement).offsetHeight}px`;
            }
        }, [offer]);

        const paymentKeywords = useGetPaymentKeywords(deposit, duration, annualMileage, apr);
        const modelKeywords = useGetModelKeywords(
            offer,
            allowDefaultConfiguration && defaultConfiguration,
            allowDefaultConfiguration ? defaultTotalPrice : baseTotalPrice,
            allowDefaultConfiguration ? defaultMonthlyPrice : baseMonthlyPrice,
            null,
            amountRounding
        );
        const offerKeywords = useGetOfferKeywords(offer);

        try {
            return (
                <StyledCol
                    className={`${OFFER_ELEMENT_CLASS} ${disabled ? 'offer-disabled' : 'offer-enabled'}`}
                    data-testid={disabled ? 'TESTING_OFFER_DISABLED' : 'TESTING_OFFER_ENABLED'}
                    style={{ display: 'flex', width: '100%' }}
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                >
                    {isNeedHandItem ? (
                        <div className={`${className} isNeedHandItem`} ref={containerRef} id={NEED_HAND_ELEMENT_ID}>
                            <NeedHandSection />
                        </div>
                    ) : (
                        <div
                            className={`${className} ${
                                Boolean((isPromoEnabled && offer.hasPromoCode) || isMarketingStickerEnabled)
                                    ? 'with-sticker'
                                    : ''
                            }`}
                            ref={containerRef}
                        >
                            {/* hack #2 - section element so it is different from other divs so React does not mix up div elements in tree - {key} did not help */}
                            {Boolean(isMarketingStickerEnabled) && (
                                <section className="carMarketingSticker">
                                    <MarketingSticker
                                        data={isMarketingStickerEnabled as unknown as Array<IPromo>}
                                        page={EContextPage.home}
                                        fullWidth
                                    />
                                </section>
                            )}
                            <div
                                className={`carTitleWrap withDemoLabel`}
                                data-testid={`TESTING_CAR_TITLE_${offer.index}`}
                            >
                                <Link href={offerUrl}>
                                    <a tabIndex={disabled ? -1 : 0}>
                                        <h2
                                            className="carTitleWrap--link"
                                            title={getOfferCarTitle(
                                                offer,
                                                router.pathname.includes(CarJourneyType.STOCK)
                                                    ? shouldIncludeBodyStyleDescriptionForStock
                                                    : shouldIncludeBodyStyleDescriptionForMTO
                                            )}
                                            onClick={handleShowOfferClick}
                                        >
                                            {getOfferCarTitle(
                                                offer,
                                                router.pathname.includes(CarJourneyType.STOCK)
                                                    ? shouldIncludeBodyStyleDescriptionForStock
                                                    : shouldIncludeBodyStyleDescriptionForMTO
                                            )}
                                        </h2>
                                    </a>
                                </Link>
                                {offer?.totalVehiclesCount && (
                                    <div className="carTitleWrap--totalCount">
                                        {offer.totalVehiclesCount} {t('offerListItem.totalCount')}
                                    </div>
                                )}
                                {router?.query[STOCK_TYPE] === StockType.VD &&
                                    (router.pathname === `/${CarJourneyType.STOCK}` ||
                                        router.pathname === `/${CarJourneyType.CONFIGURABLE}`) && (
                                        <DemoVehicleLabel isHome withMarketingSticker={!!isMarketingStickerEnabled} />
                                    )}
                            </div>
                            <DynamicExteriorImagePreview imageUrl={image} minHeight={200} />
                            {isMarketGB ? (
                                <SOLPriceBox
                                    basePrice={baseTotalPrice}
                                    monthlyPrice={baseMonthlyPrice}
                                    catalogPrice={catalogPrice}
                                    calculateSummaryData={processCalculateSummaryDataPromise}
                                    amountRounding={amountRounding}
                                    isHomePage
                                    itemId={offer.id || offer._id}
                                    apr={apr}
                                    hasMonthlyPrices={hasMonthlyPrices}
                                    financeDetailView={financeDetailView}
                                    financeDetailText={financeDetailText}
                                />
                            ) : (
                                <PriceBox
                                    basePrice={baseTotalPrice}
                                    totalBasicPriceWithoutVat={totalBasicPriceWithoutVat}
                                    monthlyPrice={baseMonthlyPrice}
                                    catalogPrice={catalogPrice}
                                    depositPrice={depositPrice}
                                    amountRounding={amountRounding}
                                    tabIndex={disabled ? -1 : 0}
                                    calculateSummaryData={processCalculateSummaryDataPromise}
                                    isHomePage
                                    promotionalText={offer?.extraFields?.promotionalText || offer?.promotionalText}
                                    itemId={offer.id || offer._id}
                                    financeDetailView={financeDetailView}
                                    financeDetailText={financeDetailText}
                                    apr={apr}
                                    hasMonthlyPrices={hasMonthlyPrices}
                                    importInputs={offer?.importInputs ?? offer?.extraFields?.importInputs}
                                    index={index}
                                />
                            )}
                            <div className="buttonWrap">
                                <Button
                                    tabIndex={disabled ? -1 : 0}
                                    primary={!isBrandAC && !(isBrandOV && isMarketGB)}
                                    secondary={isBrandAC || (isBrandOV && isMarketGB)}
                                    onClick={handleShowOfferClick}
                                    marginTop={10}
                                    fullWidth
                                    data-testid={`TESTING_DETAIL_${offer.index}`}
                                    id={`TESTING_DETAIL_${offer.index}`}
                                >
                                    {isStockJourney ? t('label.button.showOfferStock') : t('label.button.showOffer')}
                                </Button>
                            </div>
                            {!isStockJourney && (
                                <div
                                    className={`descriptionWrap ${isMarketGB ? 'descriptionWrap-gb' : ''}`}
                                    data-testid={`TESTING_DESCRIPTION_${offer.index}`}
                                >
                                    <div
                                        className={`descriptionWrap-payment ${
                                            isMarketGB ? 'descriptionWrap-payment-gb' : ''
                                        }`}
                                    >
                                        {!isSOLEnabled && offerKeywords}
                                        {hasMonthlyPrices && (
                                            <div className="descriptionWrap-payment-sol">
                                                {isSOLEnabled && paymentKeywords}
                                                {isSOLEnabled && (
                                                    <button
                                                        className="descriptionWrap-payment-link"
                                                        data-testid={`TESTING_DESCR_${offer.index}`}
                                                        onClick={processCalculateSummaryDataPromise}
                                                    >
                                                        {t('label.link.finance')}
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {modelKeywords}
                                </div>
                            )}
                        </div>
                    )}
                </StyledCol>
            );
        } catch (e: any) {
            return <HandledComponentError error={e} />;
        }
    }
);
OfferListItemTemplate.displayName = 'OfferListItemTemplate';
export default OfferListItemTemplate;
